// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@media print {
  @page {
    size: landscape;
    margin: 0px;
    padding: 0px;
  }

  #tag {
    margin-bottom: -20px !important;
  }

  .substepPage {
    --min-width: 100% !important;
    --min-heigth: 100% !important;
  }

  .no-print {
    display: none !important;
  }

  .notShowInTag {
    display: none !important;
  }
}

.print-show {
  display: none !important;
}

.btn-print {
  font-size: larger;
  border-radius: 2.3px;
  background-color: rgb(244, 245, 248);
  box-shadow: 1px 2px 3px #e2d7d7;
}

.subProductRangePage {
  --height: 300px;
}

@media screen {
  .no-screen {
    display: none !important;
  }
}

ion-list.no-last-border {
  :last-child {
    --border-style: none !important;
  }
}
.alert-radio-label.sc-ion-alert-md {
  white-space: normal;
}

ion-input,
ion-textarea {
  text-align: end;
}

form {
  margin: 0px 15px 0px 15px;
}

.saveButton {
  margin: 0px 10px 0px 10px;
}

.headerFibers {
  margin: 10px 0px 0px 15px;
}

.contentOP {
  margin: 5px 16px 0px 16px;
}

.my-custom-interface .select-interface-option .alert-radio-label {
  font-size: small;
}

.my-custom-interface-acond .select-interface-option .alert-radio-label {
  font-size: revert;
}
.substepPage {
  --min-width: 760px;
}

.updateMessage {
  --width: 309px !important;
}

.datetime-modal {
  --border-radius: 8px;
  --width: 325px;
  --height: 434px;
}

ion-datetime {
  width: 325px;
  height: 434px;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-lightgrey {
  --ion-color-base: var(--ion-color-lightgrey);
  --ion-color-shade: var(--ion-color-lightgrey-shade);
  --ion-color-tint: var(--ion-color-lightgrey-tint);
}

.confirm-disassociate-remove-modal {
  --border-radius: 8px;
  --width: 350px;
  --height: 200px;
}
.create-step-machine{
  --border-radius: 8px;
  --width: 400px;
  --height: 400px;
}
.exclude-modal {
  --margin: auto;
  --height: 40%;
  --max-width: 90%;
  --border-radius: 16px;
  padding-left: 30%;
  text-align: center;
  @media (max-width: 992px) {
    --height: 184px;
    --width: 304px;
    padding-left: 0;
  }
}
